import React, { useState, createContext } from 'react';

export const globalContext = createContext({
	activeSection: '',
	bottomSheetIsOpen: false
});

const Provider = props => {
	const [activeSection, setActiveSection] = useState('');
	const [bottomSheetIsOpen, setBottomSheetIsOpen] = useState(false);

	return (
		<globalContext.Provider value={{
			activeSection,
			setActiveSection: sectionName => setActiveSection(sectionName),
			bottomSheetIsOpen,
			setBottomSheetIsOpen: isOpen => setBottomSheetIsOpen(isOpen)
		}}>
			{props.children}
		</globalContext.Provider>
	);
};

export const provider = ({ element }) => (
	<Provider>
		{element}
	</Provider>
);
